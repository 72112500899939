<template>
    <van-config-provider :theme-vars="myTheme">
        <van-nav-bar class="shadow-lg backdrop-filter backdrop-blur-md" :title="pageTitle" :left-text="leftText"
            :left-arrow="seeBack" fixed z-index="99" @click-left="goBack()" @click-right="showMenu()">
            <template #right v-if="seeMenu">
                <van-badge dot v-if="numberNoti > 0">
                    <van-icon name="wap-nav" size="20" />
                </van-badge>
                <van-icon v-else name="wap-nav" size="20" />
            </template>
        </van-nav-bar>
    </van-config-provider>
    <router-view :key="$route.path" />
    <!-- <router-view v-slot="{ Component }">
    <keep-alive :include="['PageClassList']">
        <component :is="Component" :key="$route.path" />
    </keep-alive>
</router-view> -->
    <div class="footer">
        <span>© 2024 PACDA</span>
        <br />
        <span>v2.04</span>
    </div>
    <van-popup v-if="userInfo" class="h-screen w-60 py-12 bg-gray-50" v-model:show="showRightMenu" position="right"
        closeable close-icon-position="top-left">
        <div class="text-center">
            <van-image v-if="school" round fit="contain" width="6rem" height="6rem"
                :src="Env.schoolPath + school.icon" />
        </div>
        <van-cell :title="lang[langCode].titleMyPage" is-link @click="goHome()" class="bg-gray-50" icon="home-o" />
        <van-cell :title="lang[langCode].titleAllClass" is-link @click="goClass()" class="bg-gray-50" icon="shop-o" />
        <van-cell :title="lang[langCode].titleMyClass" is-link @click="goMyClass()" class="bg-gray-50"
            icon="shop-collect-o" />
        <van-cell :title="lang[langCode].titleMyMessage" is-link @click="goMessage()" class="bg-gray-50" icon="chat-o">
            <template #value>
                <span v-if="numberNoti > 0" class="rounded-xl text-xs bg-red-600 text-white p-1">{{ numberNoti }}</span>
            </template>
        </van-cell>
        <van-cell :title="lang[langCode].titleMyInfo" is-link @click="goMyProfile()" class="bg-gray-50" icon="info-o" />
        <van-cell :title="lang[langCode].titleRecords" is-link @click="goPaymentProfile()" v-if="isStudent"
            class="bg-gray-50" icon="debit-pay" />
        <van-cell :title="lang[langCode].titleLogout" is-link @click="logout()" class="bg-gray-50" icon="close" />
        <br />
        <br />
        <van-cell class="bg-gray-50">
            <template #title>
                <div class="text-center">
                    <van-image :src="Env.userPath + userInfo.profile.icon" round fit="contain" width="8rem"
                        height="8rem" />
                    <br />
                    <span>{{ userInfo.profile.showName }}</span>
                    <br />
                    <span>{{ UserTypes[userInfo.userType].name[langCode] }}</span>
                    <br />
                    <span v-if="isStudent">Credits: {{ userInfo.credit }}/{{ userInfo.max }}</span>
                </div>
            </template>
        </van-cell>
    </van-popup>
</template>

<script>
import lang from "@/langs/AppLang.js";
import {
    ref,
    computed
} from "vue";
import {
    useRouter
} from "vue-router";
import {
    useStore
} from "vuex";
import {
    NavBar,
    Icon,
    Popup,
    Cell,
    Image as VanImage,
    Badge
} from "vant";
import myTheme from "@/logics/MyVantTheme.js";
import Env from "@/logics/Envs.js";
import Logout from "@/asyncs/Logout.js";
import UserTypes from "@/static_values/StaticUserTypes.js";
import Cookies from "js-cookie";

export default {
    components: {
        [NavBar.name]: NavBar,
        [Icon.name]: Icon,
        [Popup.name]: Popup,
        [Cell.name]: Cell,
        [VanImage.name]: VanImage,
        [Badge.name]: Badge
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const showRightMenu = ref(false);
        if (!Cookies.get("userLang")) {
            Cookies.set("userLang", "zh");
        } else {
            store.commit("setLang", Cookies.get("userLang"));
        }
        const goBack = function () {
            if (router.currentRoute.value.name === "Login") {
                router.push("/");
            } else {
                router.back();
            }
        }
        const goHome = function () {
            router.push("/home");
            showRightMenu.value = false;
        };
        const goClass = function () {
            router.push("/class/" + store.state.school.code);
            showRightMenu.value = false;
        };
        const goMyClass = function () {
            router.push("/viewClassList");
            showRightMenu.value = false;
        };
        const goPaymentProfile = function () {
            router.push("/paymentProfile");
            showRightMenu.value = false;
        };
        const goMessage = function () {
            router.push("/message/onlyView");
            showRightMenu.value = false;
        };
        const goMyProfile = function () {
            router.push("/signup/edit");
            showRightMenu.value = false;
        };
        const logout = function () {
            showRightMenu.value = false;
            router.push("/");
            Logout(store);
        };
        const leftText = computed(function () {
            if (store.state.seeBack) {
                return lang[store.state.lang].back;
            }
            return "";
        });
        const showMenu = function () {
            if (store.state.user) {
                showRightMenu.value = true;
            }
        };
        return {
            lang,
            langCode: computed(() => store.state.lang),
            Env,
            myTheme,
            showRightMenu,
            goHome,
            goClass,
            goMyClass,
            goBack,
            goPaymentProfile,
            goMessage,
            goMyProfile,
            logout,
            seeMenu: computed(() => store.state.seeMenu),
            seeBack: computed(() => store.state.seeBack),
            school: computed(() => store.state.school),
            pageTitle: computed(() => store.state.pageTitle),
            leftText,
            showMenu,
            userInfo: computed(() => store.state.user),
            UserTypes,
            isStudent: computed(() => UserTypes.student.value === store.state.user.userType),
            numberNoti: computed(() => store.state.numberNoti)
        };
    }
}
</script>

<style>
html,
body {
    padding: 0px;
    margin: 0px;
    background-color: rgb(203, 203, 226);
}

.footer {
    text-align: center;
    font-size: 11px;
    color: #aaa;
    margin-top: 50px;
}

#app {
    background-color: rgb(203, 203, 226);
    /* scrollbar-width: none; */
}

/* ::-webkit-scrollbar {
    width: 0px;
}

::-webkit-scrollbar-track {
    display: none;
} */
</style>
